import kebabalize from '../kebabalize'

const BUSINESS = {
	brand: 'XYZ-financial',
	family: 'XYZ',
}
const LANGUAGE = 'en';
const COUNTRY = 'ca'; //TODO: is user location always ca ?

export const buildCommonAttrs = (eventName, currentUser) => ({
  event: eventName,
  business: BUSINESS,
  user:[{
    session: currentUser?.session || null,
    language: LANGUAGE,
    country: COUNTRY,
    state: currentUser ? 'authenticated' : 'visitor'
  }],
})

export const buildPageViewAttrs = (event, history, currentUser, currentStep, currentSection) => {
  const pathInfo = history.location || null;
  const previousStep = history.location.state?.previousStep || null;
  const baseUrl = window.location.origin;

  const formName = 'Product Onboarding (Create Account)' || null
  const formId = 'product-account-create' || null
  const pageName = currentStep ? `${currentStep.name.charAt(0).toUpperCase()}${currentStep.name.slice(1)}` : document.title
  const pageId = kebabalize(`${formId}:${pageName}`).replaceAll(/([^\w\-\:])/g, '')

  const commonAttrs = buildCommonAttrs(event, currentUser)

  const gtmData = {
    dataLayer:{
      ...commonAttrs,
      page: {
        id: pageId,
        name: pageName || null,
        type: 'product-account-create',
        url_destination: currentStep ? `${baseUrl}${currentStep.path}` : null,
        url_referring: previousStep ? `${baseUrl}${previousStep.path}` : document.referrer,
        breadcrumbs: pathInfo
      },
      contents: {
        content: [{
          type: 'form',
          category: {}
        }]
      },
      forms: [{
        id: formId,
        name: formName,
        type: 'registration',
        steps_section: currentSection?.id?.charAt(0).toUpperCase() + currentSection?.id?.slice(1),
        steps_count: 14,
        steps_current: currentStep?.stepNumber,
        step_current_name: currentStep?.name
      }],
      lists: undefined
    }
  }
  return gtmData;
}
